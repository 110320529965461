import React, {useMemo} from 'react';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {getBorrowerBirthDate} from 'lib/borrower';
import {isTenurePayment, isPaymentBreakdownValueNegative, formatPaymentBreakdownValue} from 'lib/product';
import {usePrintContext} from 'contexts/print-context';
import {useBooleanToString} from 'hooks/currency';
import {parentheses} from 'lib/utils';

export function useAnnualOverallResults(props = {}) {
  const {
    product: {
      ApiRoute,
      ProductName,
      calculation: {
        BorrowerProfile: {
          Age,
          DateOfBirth,
          Liens,
          ValueOfProperty,
          ExistingMortgage: {TaxesAndInsuranceReserve} = {},
        } = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        FundAccess,
        Lesa,
        Margin,
        OverrideClosingCosts,
        RemainingPrincipalLimit,
        TenureResult: {MaxMonthlyPayment} = {},
        ExpectedRate,
        InitialRate,
        MIP,
        TotalInitialRate,
      } = {},
    } = {},
  } = props;
  const {t, cashLabel, override} = useTranslation();
  const {printing} = usePrintContext();

  const {booleanToString} = useBooleanToString();

  const overallResults = useMemo(() => {
    const overallResultsData = [
      {
        label: t('illustratorTranslation:illustration.overall results.home value'),
        value: formatCurrency(ValueOfProperty),
        order: 1,
        id: 'home-value',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.principal limit'),
        tooltip: t('illustratorTranslation:illustration.overall results.principal limit tooltip'),
        value: formatCurrency(PrincipalLimit),
        order: 2,
        id: 'principal-limit',
        options: [
          {
            label: t('illustratorTranslation:illustration.overall results.mortgage balance'),
            value: parentheses(formatCurrency(Liens)),
            useColorOptions: {value: 'red'},
            order: 3,
            id: 'mortgage-balance',
          },
          {
            label: t('illustratorTranslation:illustration.overall results.closing cost'),
            value: parentheses(formatCurrency(ClosingCost)),
            useColorOptions: {value: 'red'},
            order: 4,
            id: 'closing-cost',
          },
        ],
      },
      {
        label: cashLabel('illustratorTranslation:illustration.overall results.cash at close', {
          cash: CashAtClose,
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.cash at close tooltip', {
          product: {ApiRoute},
        }),
        value: formatPaymentBreakdownValue(CashAtClose),
        useColorOptions: {value: isPaymentBreakdownValueNegative(CashAtClose) ? 'red' : undefined},
        order: 6,
        id: 'cash-at-close',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.remaining principal limit'),
        tooltip: t('illustratorTranslation:illustration.overall results.remaining principal limit tooltip'),
        value: formatCurrency(RemainingPrincipalLimit),
        order: 7,
        id: 'remaining-principal-limit',
      },
    ];

    if (isTenurePayment({FundAccess})) {
      overallResultsData.push({
        label: t('illustratorTranslation:illustration.overall results.tenure payment amount'),
        value: formatCurrency(MaxMonthlyPayment),
        id: 'tenure-payment-amount',
      });
    }

    return overallResultsData;
  }, [
    t,
    ValueOfProperty,
    PrincipalLimit,
    Liens,
    ClosingCost,
    override,
    cashLabel,
    ApiRoute,
    CashAtClose,
    RemainingPrincipalLimit,
    FundAccess,
    MaxMonthlyPayment,
  ]);

  // This is used on the Product PDF Printout page
  const overallResultsSecond = useMemo(() => {
    const data = [
      {
        label: t('illustratorTranslation:illustration.overall results.date of birth'),
        value: getBorrowerBirthDate(DateOfBirth),
        order: 1,
        id: 'date-of-birth',
      },
      {
        label: t('translation:justText.estimated age'),
        tooltip: t('translation:justText.estimated age tooltip'),
        value: Age,
        order: 2,
        id: 'estimated-age',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.margin'),
        value: formatPercent(Margin),
        order: 3,
        id: 'margin',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.expected rate'),
        tooltip: t('illustratorTranslation:illustration.overall results.expected rate tooltip'),
        value: formatPercent(ExpectedRate),
        order: 3,
        id: 'expected-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.initial rate'),
        value: formatPercent(InitialRate),
        order: 4,
        id: 'initial-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.mortgage insurance premium'),
        value: formatPercent(MIP),
        order: 5,
        id: 'mortgage-insurance-premium',
      },
      {
        label: override('illustratorTranslation:illustration.overall results.total initial loan rate', {
          product: {ApiRoute},
        }),
        value: formatPercent(TotalInitialRate),
        order: 6,
        id: 'total-initial-loan-rate',
      },
    ];

    if (printing) {
      data.push(
        {
          label: t('illustratorTranslation:illustration.overall results.fund access'),
          value: FundAccess,
          order: 7,
          id: 'fund-access',
        },
        {
          label: t('illustratorTranslation:illustration.overall results.override closing costs'),
          value: booleanToString(OverrideClosingCosts),
          order: 8,
          id: 'override-closing-costs',
        },
      );
    }

    return data;
  }, [
    t,
    DateOfBirth,
    Age,
    Margin,
    ExpectedRate,
    InitialRate,
    MIP,
    override,
    ApiRoute,
    TotalInitialRate,
    printing,
    FundAccess,
    booleanToString,
    OverrideClosingCosts,
  ]);

  return {
    overallResults,
    overallResultsSecond,
  };
}
